<template>
  <v-container class="d-flex justify-center">
    <v-card width="850px" outlined>
      <v-card-title>Select Products and Sales</v-card-title>
      <v-card-text>
        <p>Month</p>
        <v-select
          v-model="selectedMonth"
          :items="months"
          label="Select Month"
          outlined
          class="mb-4"
        ></v-select>
        <v-row>
          <v-col cols="6">
            <p>Select a broker</p>
            <new-consultant-picker @consultant-selected="onBrokerSelect" />
          </v-col>
          <v-col cols="6">
            <p>Select the client</p>
            <v-select
              v-model="selectedClient"
              :items="clients"
              :loading="isLoading"
              label="Select Client"
              item-text="fullName"
              item-value="id"
              @change="onClientSelect"
            />
          </v-col>
        </v-row>

        <!-- Product and Quantity Section -->
        <v-row
          v-for="(entry, index) in productEntries"
          :key="index"
          class="mt-4"
        >
          <v-col cols="6">
            <new-product-picker
              @selected-product="handleProductSelected(index, entry.productId)"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="entry.quantity"
              label="Quantity"
              type="number"
              outlined
              @input="updateMonthlySales"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-btn icon @click="removeProductRow(index)">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn text color="primary" class="mt-4" @click="addProductRow">
          <v-icon left>mdi-plus-circle</v-icon>Add Product
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveSales">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import NewConsultantPicker from "../../components/Base/Pickers/NewConsultantPicker.vue";
import { GET_USERS_SMALL } from "@/queries/users.js";
import { GET_USER_PRODUCTS_LIGHT } from "../../../queries";
import NewProductPicker from "../../components/Base/Pickers/NewProductPicker.vue";

export default {
  name: "Sales",
  components: {
    NewConsultantPicker,
    NewProductPicker,
  },
  data() {
    return {
      selectedMonth: null,
      clients: [],
      broker: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      isLoading: false,
      selectedClient: null,
      products: [], // Stores fetched products for the selected client
      productEntries: [
        {
          productId: null,
          quantity: 0,
        },
      ],
      monthlySales: {},
    };
  },
  methods: {
    async onBrokerSelect(brokerId) {
      this.broker = brokerId;
      const query = { consultant: brokerId };
      try {
        const { data } = await this.$apollo.query({
          query: GET_USERS_SMALL,
          variables: {
            limit: 10,
            page: 1,
            query,
          },
        });
        this.clients = data.users.records.map((user) => ({
          id: user.id,
          fullName: `${user.firstName} ${user.lastName}`,
        }));
      } catch (err) {
        console.error("Error fetching client details:", err);
        alert("Failed to fetch client details. Please try again later.");
      }
    },
    async onClientSelect(clientId) {
      this.selectedClient = clientId;
      this.products = []; // Reset products when client changes
      const query = { user: clientId };
      try {
        const { data } = await this.$apollo.query({
          query: GET_USER_PRODUCTS_LIGHT,
          variables: {
            limit: 10,
            skip: 0,
            query,
          },
        });
        this.products = data.userProducts.records.map((product) => ({
          id: product.id,
          productName: product.productName,
        }));
      } catch (err) {
        console.error("Error fetching products:", err);
        alert("Failed to fetch products for the selected client.");
      }
    },

    handleProductSelected(index, productId) {
      this.$set(this.productEntries[index], "productId", productId);
      this.updateMonthlySales();
    },
    updateMonthlySales() {
      if (!this.selectedMonth) return;

      this.$set(this.monthlySales, this.selectedMonth, []);
      this.productEntries.forEach((entry) => {
        if (entry.productId !== null && entry.quantity > 0) {
          this.monthlySales[this.selectedMonth].push({
            productId: entry.productId,
            quantity: entry.quantity,
          });
        }
      });
    },
    addProductRow() {
      this.productEntries.push({
        productId: null,
        quantity: 0,
      });
    },
    removeProductRow(index) {
      this.productEntries.splice(index, 1);
      this.updateMonthlySales();
    },
    saveSales() {
      const salesData = {
        month: this.selectedMonth,
        broker: this.broker,
        client: this.selectedClient,
        products: this.productEntries.filter(
          (entry) => entry.productId !== null && entry.quantity > 0
        ),
      };
      console.log("Saved Sales Data:", salesData);
      // Integrate the save logic here (e.g., API call).
    },
  },
};
</script>

<style scoped>
.mdi-plus-circle,
.mdi-minus-circle {
  cursor: pointer;
  font-size: 24px;
}
</style>
